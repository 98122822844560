/* eslint-disable no-console */

import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { DocumentContext } from "~context/DocumentContext";
import { fancyError } from "~utils/helpers";

class AlphaVideoComponent extends Component {
  state = {
    canvasRendered: false,
    nativeHeight: 200,
    nativeWidth: 200
  };

  //

  bufferCanvasRef = React.createRef();

  outputCanvasRef = React.createRef();

  videoRef = React.createRef();

  //

  buffer;

  destroyed = false;

  interval;

  loaded = false;

  loadAttempts = 0;

  playing = false;

  output;

  timeouts = [];

  //

  componentDidMount() {
    setTimeout(() => {
      this.load();
    }, 500);

    if (window) {
      window.onblur = () => {
        this.destroyed = true;
      };

      window.onfocus = () => {
        this.destroyed = false;

        this.start();
      };
    }
  }

  componentWillUnmount() {
    this.destroyed = true;

    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.timeouts.length) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  }

  //

  load = () => {
    if (!this.bufferCanvasRef.current || !this.outputCanvasRef.current) {
      setTimeout(() => {
        this.load();
      }, 1000);

      return;
    }

    this.buffer = this.bufferCanvasRef.current.getContext(`2d`);
    this.output = this.outputCanvasRef.current.getContext(`2d`);

    this.loaded = true;

    const playPromise = this.play();

    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          this.start();
        })
        .catch(error => {
          fancyError(error);
        });
    } else {
      setTimeout(() => {
        this.start();
      }, 2000);
    }
  };

  //

  start = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      if (this.destroyed) {
        clearInterval(this.interval);

        return;
      }

      requestAnimationFrame(() => {
        this.processFrame();
      });
    }, 60);
  };

  processFrame = () => {
    if (this.destroyed) {
      if (this.interval) {
        clearInterval(this.interval);
      }

      return;
    }

    if (!this.buffer || !this.videoRef || !this.videoRef.current) {
      return;
    }

    this.setState({
      nativeHeight: this.videoRef.current.videoHeight / 2,
      nativeWidth: this.videoRef.current.videoWidth
    });

    //

    try {
      this.buffer.drawImage(this.videoRef.current, 0, 0);
    } catch (e) {
      console.error(`Couldn't drawImage: ${e}`);
      return;
    }

    //

    let image;

    try {
      image = this.buffer.getImageData(
        0,
        0,
        this.state.nativeWidth,
        this.state.nativeHeight
      );
    } catch (e) {
      console.warn(`Couldn't getImageData for image: ${e}`);
      return;
    }

    const imageData = image.data;

    //

    let alphaData;

    try {
      alphaData = this.buffer.getImageData(
        0,
        this.state.nativeHeight,
        this.state.nativeWidth,
        this.state.nativeHeight
      ).data;
    } catch (e) {
      console.warn(`Couldn't getImageData for alphaData: ${e}`);
      return;
    }

    //

    for (let i = 3, len = imageData.length; i < len; i += 4) {
      imageData[i] = alphaData[i - 1];
    }

    this.output.putImageData(
      image,
      0,
      0,
      0,
      0,
      this.state.nativeWidth,
      this.state.nativeHeight
    );

    if (!this.state.canvasRendered) {
      this.setState({
        canvasRendered: true
      });
    }
  };

  //

  pause = () => {
    if (!this.playing) {
      return;
    }

    this.playing = false;

    clearInterval(this.interval);

    this.videoRef.current.pause();
  };

  play = () => {
    if (this.playing) {
      return undefined;
    }

    this.playing = true;

    return this.videoRef.current.play();
  };

  //

  getVideoTransformX = () => {
    const { documentContext, cursorMax } = this.props;

    if (documentContext.device !== `desktop`) {
      return `0px`;
    }

    let delta = documentContext.cursorCenterDeltaX * 50;

    if (cursorMax) {
      delta = documentContext.cursorCenterDeltaX * 250;
    }

    return `${delta}px`;
  };

  getVideoTransformY = () => {
    const { documentContext, cursorMax } = this.props;

    if (documentContext.device !== `desktop`) {
      return `0px`;
    }

    let delta = documentContext.cursorCenterDeltaY * 50;

    if (cursorMax) {
      delta = documentContext.cursorCenterDeltaY * 250;
    }

    return `${delta}px`;
  };

  //

  render() {
    const { className, documentContext, src } = this.props;
    const { nativeHeight, nativeWidth } = this.state;

    let transform = `translate3d(0, 0, 0)`;

    if (documentContext.device === `desktop`) {
      transform = `translate3d(${this.getVideoTransformX()}, ${this.getVideoTransformY()}, 0)`;
    }

    return (
      <div
        role="presentation"
        className={`alpha-video ${className} gpu`}
        style={{
          transform
        }}
      >
        <video ref={this.videoRef} className="hidden" loop muted playsInline>
          <source src={src} type='video/mp4; codecs="avc1.42E01E"'></source>
        </video>

        <canvas
          className="hidden"
          ref={this.bufferCanvasRef}
          width={nativeWidth}
          height={nativeHeight * 2}
        ></canvas>

        <canvas
          className="w-full relative block"
          ref={this.outputCanvasRef}
          width={nativeWidth}
          height={nativeHeight}
        ></canvas>
      </div>
    );
  }
}

AlphaVideoComponent.defaultProps = {
  className: ``,
  cursorMax: false
};

AlphaVideoComponent.propTypes = {
  className: PropTypes.string,
  cursorMax: PropTypes.bool,
  documentContext: PropTypes.shape({
    device: PropTypes.string.isRequired,
    cursorCenterDeltaX: PropTypes.number.isRequired,
    cursorCenterDeltaY: PropTypes.number.isRequired
  }).isRequired,
  src: PropTypes.string.isRequired
};

//

const AlphaVideo = ({ className, cursorMax, src }) => {
  const documentContext = useContext(DocumentContext);

  return (
    <AlphaVideoComponent
      className={className}
      cursorMax={cursorMax}
      documentContext={documentContext}
      src={src}
    />
  );
};

AlphaVideo.defaultProps = {
  className: ``,
  cursorMax: false
};

AlphaVideo.propTypes = {
  className: PropTypes.string,
  cursorMax: PropTypes.bool,
  src: PropTypes.string.isRequired
};

export default AlphaVideo;
