import React from "react";
import PropTypes from "prop-types";

const SVGShare = props => {
  // TODO: as import
  const colors = {
    black: `#000000`,
    blackish: `#030303`,
    camelot: `#9e3259`,
    cararra: `#eff0e8`,
    brown: `#BEB4A5`,
    geyser: `#cfd8e3`,
    green: `#65dba2`,
    grey: `#d1d1d1`,
    "light-grey": `#E4E5DD`,
    mimosa: `#f4ffb2`,
    minsk: `#3622b0`,
    monalisa: `#ffa99d`,
    peach: `#ffc4c0`,
    pink: `#f3adcf`,
    purple: `#581aa1`,
    red: `#ff0000`,
    salem: `#077d4b`,
    sienna: `#76161d`,
    sisal: `#c3b8a6`,
    teal: `#008888`,
    burntish: `#9E9483`,
    burnt: `#B0A592`,
    burnter: `#C3B8A6`,
    burntest: `#E6DFD3`,
    ultramarine: `#0f00be`,
    wheat: `#f3d5a8`,
    white: `#ffffff`,
    yellow: `#ffd81a`
  };

  return (
    <svg
      className="w-full relative block"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7071 2.29301C12.3166 1.90248 11.6834 1.90248 11.2929 2.29301L4.92893 8.65697C4.53841 9.04749 4.53841 9.68066 4.92893 10.0712C5.31946 10.4617 5.95262 10.4617 6.34315 10.0712L12 4.41433L17.6569 10.0712C18.0474 10.4617 18.6805 10.4617 19.0711 10.0712C19.4616 9.68066 19.4616 9.04749 19.0711 8.65697L12.7071 2.29301ZM13 13.5239L13 3.00012L11 3.00012L11 13.5239L13 13.5239Z"
        fill={colors[props.color]}
      />
      <path
        d="M4 13.5239V20.0001H20V13.5239"
        stroke={colors[props.color]}
        strokeWidth="2"
      />
    </svg>
  );
};

SVGShare.defaultProps = {
  color: `white`
};

SVGShare.propTypes = {
  color: PropTypes.string
};

export default SVGShare;
