import React from "react";
import PropTypes from "prop-types";

const Footer = ({ colorClass, type }) => (
  <footer
    className={`footer footer--${type} w-full absolute bottom-0 right-0 left-0 z-30 p-6 xs:py-2`}
  >
    <nav
      className={`w-full flex xs:flex-col justify-between text-${colorClass} xs:text-center`}
    >
      <h3 className="footer__link xs:py-2 b3">
        <span className="xs:py-2">A project by </span>

        <a
          href="https://www.relishworks.com"
          target="_blank"
          rel="noopener noreferrer"
          className="xs:py-2 underline"
        >
          Relish Works
        </a>
      </h3>

      <h3 className="footer__link xs:pt-2 xs:pb-8 b3">
        <span className="xs:py-2">Site by </span>

        <a
          href="https://www.loveandmoney.agency"
          target="_blank"
          rel="noopener noreferrer"
          className="xs:py-2 underline"
        >
          &lt;3 + $
        </a>
      </h3>
    </nav>
  </footer>
);

Footer.defaultProps = {
  colorClass: ``
};

Footer.propTypes = {
  colorClass: PropTypes.string,
  type: PropTypes.string.isRequired
};

export default Footer;
