import React from "react";
import PropTypes from "prop-types";
import Footer from "~components/Footer";
import Header from "~components/Header";

const Layout = props => {
  const {
    children,
    className,
    footerClass,
    footerType,
    headerClass,
    outerClassName
  } = props;

  return (
    <>
      <Header colorClass={headerClass} />

      <div className={`layout ${outerClassName || ``}`}>
        <main className={`layout__container ${className}`}>{children}</main>
      </div>

      <Footer colorClass={footerClass} type={footerType} />
    </>
  );
};

Layout.defaultProps = {
  footerClass: `white`,
  footerType: `default`,
  headerClass: `white`,
  outerClassName: null
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  footerClass: PropTypes.string,
  footerType: PropTypes.string,
  headerClass: PropTypes.string,
  outerClassName: PropTypes.string
};

export default Layout;
