import React from "react";
import PropTypes from "prop-types";

const SVGMenu = props => (
  <svg
    className="w-full relative block"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line x1="3" y1="4" x2="21" y2="4" stroke={props.color} strokeWidth="2" />
    <line x1="3" y1="12" x2="21" y2="12" stroke={props.color} strokeWidth="2" />
    <line x1="3" y1="20" x2="21" y2="20" stroke={props.color} strokeWidth="2" />
  </svg>
);

SVGMenu.defaultProps = {
  color: `white`
};

SVGMenu.propTypes = {
  color: PropTypes.string
};

export default SVGMenu;
