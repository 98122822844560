import React from "react";
import PropTypes from "prop-types";

const SVGLogo = props => (
  <svg
    className="w-full relative block"
    viewBox="0 0 222 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.8979 0.475248H23.7942L16.4835 18.4328L9.10373 0.475248H4V23.4908H7.44847V5.60113L14.7937 23.4908H18.0697L25.4495 5.46535V23.4908H28.8979V0.475248Z"
      fill={props.color}
    />
    <path
      d="M52.6056 0.475248H48.364L41.9843 10.761L35.6047 0.475248H31.1561L40.0187 14.2574V23.4908H43.7086V14.2914L52.6056 0.475248Z"
      fill={props.color}
    />
    <path
      d="M58.5278 3.97171H72.1837V0.475248H54.8379V23.4908H58.5278V13.4767H70.6319V10.082H58.5278V3.97171Z"
      fill={props.color}
    />
    <path
      d="M79.7391 22.4724C81.6358 23.4908 83.7049 24 85.9464 24C88.1879 24 90.2569 23.4908 92.1536 22.4724C94.0503 21.454 95.5676 20.0283 96.7056 18.1952C97.8436 16.3621 98.3953 14.2914 98.3953 11.983C98.3953 9.50495 97.8436 7.36634 96.7056 5.56719C95.5676 3.76803 94.0503 2.37624 92.1536 1.42574C90.2569 0.475247 88.1879 0 85.9464 0C83.7049 0 81.6358 0.509193 79.7391 1.52758C77.8425 2.54597 76.3251 3.97171 75.1871 5.80481C74.0491 7.63791 73.4974 9.74257 73.4974 12.0849C73.4974 14.3932 74.0491 16.4639 75.1871 18.2631C76.3251 20.0622 77.8425 21.488 79.7391 22.5064V22.4724ZM90.4294 19.3154C89.1189 20.0622 87.6016 20.4356 85.9119 20.4356C84.1532 20.4356 82.6358 20.0622 81.3254 19.3494C80.015 18.6365 79.0149 17.6521 78.3252 16.396C77.6356 15.14 77.2907 13.7482 77.2907 12.2207C77.2907 10.6591 77.6355 9.23338 78.3597 7.94342C79.0839 6.65347 80.084 5.63508 81.3944 4.88826C82.7048 4.14144 84.2221 3.76803 85.9119 3.76803C87.6361 3.76803 89.1534 4.14144 90.4638 4.88826C91.7743 5.63508 92.7743 6.65347 93.464 7.94342C94.1537 9.23338 94.4986 10.6591 94.4986 12.2207C94.4986 13.7482 94.1537 15.14 93.4295 16.396C92.7053 17.6521 91.7053 18.6704 90.3949 19.4173L90.4294 19.3154Z"
      fill={props.color}
    />
    <path
      d="M106.808 22.4724C108.705 23.4908 110.774 24 113.015 24C115.257 24 117.326 23.4908 119.223 22.4724C121.119 21.454 122.637 20.0283 123.775 18.1952C124.913 16.3621 125.464 14.2914 125.464 11.983C125.464 9.50495 124.913 7.36634 123.775 5.56719C122.637 3.76803 121.119 2.37624 119.223 1.42574C117.326 0.475247 115.257 0 113.015 0C110.774 0 108.705 0.509193 106.808 1.52758C104.912 2.54597 103.394 3.97171 102.256 5.80481C101.118 7.63791 100.567 9.74257 100.567 12.0849C100.567 14.3932 101.118 16.4639 102.256 18.2631C103.394 20.0622 104.912 21.488 106.808 22.5064V22.4724ZM117.498 19.3154C116.188 20.0622 114.671 20.4356 112.981 20.4356C111.222 20.4356 109.705 20.0622 108.395 19.3494C107.084 18.6365 106.084 17.6521 105.394 16.396C104.705 15.14 104.36 13.7482 104.36 12.2207C104.36 10.6591 104.705 9.23338 105.429 7.94342C106.153 6.65347 107.153 5.63508 108.463 4.88826C109.774 4.14144 111.291 3.76803 112.981 3.76803C114.705 3.76803 116.223 4.14144 117.533 4.88826C118.843 5.63508 119.843 6.65347 120.533 7.94342C121.223 9.23338 121.568 10.6591 121.568 12.2207C121.568 13.7482 121.223 15.14 120.499 16.396C119.774 17.6521 118.774 18.6704 117.464 19.4173L117.498 19.3154Z"
      fill={props.color}
    />
    <path
      d="M146.223 3.46252C143.947 1.49364 140.809 0.509193 136.809 0.509193H128.67V23.5248H136.809C140.809 23.5248 143.947 22.5403 146.223 20.5714C148.499 18.6025 149.637 15.7511 149.637 11.983C149.637 8.28289 148.499 5.4314 146.223 3.46252ZM141.809 19.0099C140.498 19.6888 138.912 20.0283 137.084 20.0283H132.36V4.00566H137.084C139.878 4.00566 142.016 4.71853 143.533 6.14427C145.05 7.57001 145.809 9.5389 145.809 12.017C145.809 13.5785 145.464 14.9703 144.775 16.1924C144.085 17.4144 143.085 18.3649 141.774 19.0438L141.809 19.0099Z"
      fill={props.color}
    />
    <path
      d="M152.877 0.475248V23.4908H156.567V0.475248H152.877Z"
      fill={props.color}
    />
    <path
      d="M164.541 3.97171H178.266V0.475248H160.851V23.4908H178.266V19.9943H164.541V13.4767H176.714V10.082H164.541V3.97171Z"
      fill={props.color}
    />
    <path
      d="M181.848 23.0156C182.4 23.5248 183.055 23.7963 183.848 23.7963C184.641 23.7963 185.297 23.5587 185.814 23.0495C186.331 22.5403 186.573 21.8953 186.573 21.1146C186.573 20.3338 186.297 19.6888 185.745 19.1796C185.193 18.6704 184.538 18.4328 183.779 18.4328C182.986 18.4328 182.331 18.7044 181.814 19.2136C181.296 19.7228 181.021 20.3677 181.021 21.1485C181.021 21.8953 181.296 22.5403 181.848 23.0495V23.0156Z"
      fill={props.color}
    />
    <path
      d="M189.059 0.475248V23.4908H192.749V0.475248H189.059Z"
      fill={props.color}
    />
    <path
      d="M214.586 3.46252C212.31 1.49364 209.172 0.509193 205.172 0.509193H197.033V23.5248H205.172C209.172 23.5248 212.31 22.5403 214.586 20.5714C216.862 18.6025 218 15.7511 218 11.983C218 8.28289 216.862 5.4314 214.586 3.46252ZM210.172 19.0099C208.862 19.6888 207.275 20.0283 205.448 20.0283H200.723V4.00566H205.448C208.241 4.00566 210.379 4.71853 211.896 6.14427C213.414 7.57001 214.172 9.5389 214.172 12.017C214.172 13.5785 213.827 14.9703 213.138 16.1924C212.448 17.4144 211.448 18.3649 210.137 19.0438L210.172 19.0099Z"
      fill={props.color}
    />
  </svg>
);

SVGLogo.defaultProps = {
  color: `white`
};

SVGLogo.propTypes = {
  color: PropTypes.string
};

export default SVGLogo;
