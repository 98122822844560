/* eslint-disable react/prop-types */

import React, { Component, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";

import SVGClose from "~components/svg/SVGClose";
import SVGLogo from "~components/svg/SVGLogo";
import SVGMenu from "~components/svg/SVGMenu";
import SVGShare from "~components/svg/SVGShare";

class HeaderComponent extends Component {
  state = {
    mounted: false
  };

  timeouts = [];

  componentDidMount() {
    this.timeouts.push(
      setTimeout(() => {
        this.setState({
          mounted: true
        });
      }, 150)
    );
  }

  componentWillUnmount() {
    this.clearTimeouts();
  }

  clearTimeouts = () => {
    if (this.timeouts.length > 0) {
      this.timeouts.forEach(timeout => {
        clearTimeout(timeout);
      });
    }
  };

  //

  share = () => {
    const { appContext, documentContext } = this.props;

    if (documentContext.device === `desktop` || !window.navigator.share) {
      disableBodyScroll();

      appContext.setShareMenuActive(true);

      return;
    }

    const title = `MYFOODIE.ID`;

    window.navigator.share({
      title,
      url: appContext.shareUrl
    });
  };

  toggleMenu = () => {
    const { appContext } = this.props;

    if (appContext.menuActive) {
      clearAllBodyScrollLocks();
    } else {
      disableBodyScroll();
    }

    appContext.setMenuActive(!appContext.menuActive);
  };

  //

  render() {
    const { appContext, colorClass, documentContext } = this.props;
    const { mounted } = this.state;
    const { device } = documentContext;

    return (
      <>
        {mounted && (
          <header
            className={`${colorClass} header animation-appear w-full fixed top-0 right-0 left-0 z-50 flex items-center justify-between p-1`}
          >
            <button
              type="button"
              className={`header__button relative flex items-center justify-center p-3 cursor-pointer f4 whitespace-no-wrap ${
                appContext.menuActive
                  ? `header__button text-white`
                  : `text-${colorClass}`
              }`}
              onClick={this.toggleMenu}
            >
              {(device === `desktop` && (
                <>{(appContext.menuActive && `Close`) || `Menu`}</>
              )) || (
                <>
                  {(appContext.menuActive && <SVGClose color="white" />) || (
                    <SVGMenu color={colorClass} />
                  )}
                </>
              )}
            </button>

            <Link
              to="/"
              className="header__logo relative flex items-center justify-center"
            >
              <SVGLogo color={appContext.menuActive ? `white` : colorClass} />
            </Link>

            <button
              type="button"
              className={`header__button relative flex items-center justify-center p-3 f4 whitespace-no-wrap ${
                appContext.menuActive
                  ? `opacity-0 pointer-events-none`
                  : `text-${colorClass}`
              }`}
              onClick={this.share}
            >
              {(device === `desktop` && `Share`) || (
                <SVGShare color={colorClass} />
              )}
            </button>
          </header>
        )}
      </>
    );
  }
}

HeaderComponent.propTypes = {
  colorClass: PropTypes.string.isRequired
};

const Header = ({ colorClass }) => {
  const appContext = useContext(AppContext);
  const documentContext = useContext(DocumentContext);

  return (
    <HeaderComponent
      appContext={appContext}
      colorClass={colorClass}
      documentContext={documentContext}
    />
  );
};

Header.propTypes = {
  colorClass: PropTypes.string.isRequired
};

export default Header;
